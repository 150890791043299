
import ValidationMixin from "@/mixins/validation";
import { RoleTypes, SystemRole } from "@/store/modules/role/role.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const roleX = namespace("Role");

@Component({})
export default class EditRole extends Mixins(ValidationMixin) {
  @roleX.State(RoleTypes.DELETE_ROLE_STATE)
  public deleteRole!: DefaultState;

  @roleX.State(RoleTypes.ROLE_DATA)
  public systemRole!: SystemRole;

  @roleX.Mutation(RoleTypes.SET_DELETE_ROLE_DIALOG)
  public setDeleteRole!: (deleteRole: boolean) => void;

  @roleX.Action(RoleTypes.DELETE_ROLE)
  public deleteSystemRole!: () => Promise<void>;

  public updateRoleStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteRoleDialog(): void {
    this.setDeleteRole(false);
  }
}
